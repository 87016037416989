import './Footer.css';

import { Link } from 'react-router-dom';
import facebook from './facebook-logo.png';
import instagram from './instagram-logo.png';
import yelp from './yelp-logo.png';
import logo from './M-circle.svg';
import google from '../Contact/google.png';

const Footer = () => {

  const scrollToTop = () => {
    const topElement = document.getElementById('top');
    if (topElement) {
      topElement.scrollTop({ behavior: 'smooth' });
    }
  }

  return (
    <div className='bg-gray-300 text-black py-10 custom-footer-container'>
      <div className='container mx-auto flex flex-wrap'>

        <div className='w-full md:w-1/4 px-4 text-center'>
          <h3 className='text-xl underline text-center'>Contact info:</h3>
          <div className='flex mt-2 mb-2 flex justify-center'>
            <i className="fas fa-phone mr-2 custom-phone-icon"></i>
            <p>Call us if you have questions!
              <a className='text-blue-800 custom-footer-number footer-num-2' href='tel:661-878-8170'> (661)-878-8170</a>
            </p>
          </div>
          <a className='text-blue-800 custom-footer-number-2 footer-num-2' href='tel:661-878-8170'> (661)-878-8170</a>

          <div className='flex custom-footer-addy'>
            <i className="mr-2 fa-solid fa-location-dot"></i>
            <div>
              <a href="https://www.google.com/maps/search/?api=1&query=520 W Palmdale Blvd Q, Palmdale, Ca 93551" target="_blank" rel="noreferrer">520 W Palmdale Blvd Q, Palmdale, Ca 93551</a>
            </div>
          </div>

          <p className='mt-2 mb-8'>Mountain View Wellness and Family Medical Corporation</p>

        </div>

        <div className='w-full md:w-1/4 px-4 text-center md:flex md:flex-col'>
          <h3 className=' text-xl underline'>Social Links</h3>
          <div className='flex justify-around items-center md:flex-col md:place-content-center'>
            {/* <a href='https://www.google.com' target='_blank' rel="noreferrer">
              <img className='w-12 h-12 mt-2 custom-hover-social-img' src={facebook} alt=''></img>
            </a> */}
            <a href='https://www.google.com/maps/place/Mountain+View+Wellness+and+Family+Medical+Corporation/@34.5835745,-118.140039,17z/data=!4m16!1m9!3m8!1s0x80c2593aa5e9ecd5:0xe0bf4e09ace3a629!2sMountain+View+Wellness+and+Family+Medical+Corporation!8m2!3d34.5835745!4d-118.140039!9m1!1b1!16s%2Fg%2F11vz5lv3v6!3m5!1s0x80c2593aa5e9ecd5:0xe0bf4e09ace3a629!8m2!3d34.5835745!4d-118.140039!16s%2Fg%2F11vz5lv3v6?entry=ttu' target='_blank' rel="noreferrer">
              <img className='w-12 h-12 mt-2 custom-hover-social-img' src={google} alt=''></img>
            </a>
            {/* <a href='https://www.google.com' target='_blank' rel="noreferrer">
              <img className='w-12 h-12 mt-2 custom-hover-social-img' src={yelp} alt=''></img>
            </a> */}
          </div>
        </div>

        <div className='w-full md:w-1/4 px-4'>
          <h3 className='text-xl underline text-center mt-6 md:mt-0'>Quick Links:</h3>
          <div className='flex flex-wrap text-center justify-center md:flex md:flex-col'>
            <Link id="top" onClick={scrollToTop} className='text-blue-800 mt-2 custom-footer-link pl-2' to='/new-patients'>New Patients</Link>
            <Link id="top" onClick={scrollToTop} className='text-blue-800 mt-2 custom-footer-link pl-2' to='/about'>About</Link>
            <Link id="top" onClick={scrollToTop} className='text-blue-800 mt-2 custom-footer-link pl-2' to='/reviews'>Reviews</Link>
            <Link id="top" onClick={scrollToTop} className='text-blue-800 mt-2 custom-footer-link pl-2' to='/services'>Services</Link>
            <Link id="top" onClick={scrollToTop} className='text-blue-800 mt-2 custom-footer-link pl-2' to='/faq'>FAQ</Link>
            <Link id="top" onClick={scrollToTop} className='text-blue-800 mt-2 custom-footer-link pl-2' to='/contact'>Contact</Link>
          </div>
        </div>

        <div className='w-full md:w-1/4 px-4'>
          <h3 className='text-xl custom-footer-hours mt-8 text-center md:mt-0'>Hours Of Operation:</h3>
          <p className='mt-2 text-center'>Monday - Friday:</p>
          <p className='mt-1 text-green-600 text-center'>9:00am - 5:00pm</p>
          <div className='mt-2'>
            <p className='mt-1 text-center'>Saturday - Sunday:</p>
            <p className='text-red-600 text-center'>Closed</p>
          </div>
        </div>
      </div>
      <Link to='/' id="top" onClick={scrollToTop}><img className='w-32 h-32 mt-4 mx-auto rounded-full border-2 border-black footer-logo ' src={logo} alt='LOGO'></img></Link>
    </div>
  );
};

export default Footer;
