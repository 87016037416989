import "./WeightLoss.css";

const WeightLoss = () => {
  return (
    <div className='service-background-gradient'>
            <div className="service-background-gradient md:h-[60vh] w-full flex flex-col">
                <h2 className="text-center text-3xl py-6 underline">Weight Loss Programs</h2>
                <div className="flex-1 flex items-center justify-center">
                    <p className="text-center text-2xl md:text-5xl lg:px-24 mb-8 md:mb-24">This page is currently under construction. For more information about this service, please contact our office directly.
                     </p>
                </div>
            </div>
        </div>
  );
};

export default WeightLoss;
